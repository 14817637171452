
export default {
  Canvas: {
    CanvasWidth: 100,
    CanvasHeight: 20,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#757575',
    CanvasFillColor: 'transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 100,
    CanvasChartHeight: 20,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {COL: 'OWNER_ID/UNIT/SUBSYS_NO', TEXT: 'System group/Unit/Subsystem'}
  ],

  Queries: {

    SQL1: [
      {
        TEXT_SIZE: 12,
        TEXT_COLOR: '#AAAAAA',
        NAME: 'N',
      }
    ],

  },

}